@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}

body {
	font: 400 16px Roboto, sans-serif;
	background: #ffffff;
	-webkit-font-smoothing: antialiased;
	padding: 30px;
}

input,
button,
select,
option,
textarea {
	font: 400 14px Roboto, sans-serif;
}

button {
	cursor: pointer;
}

form input, select {
	/* width: 30%; */
	height: 30px;
	color: #333;
	border: 1px solid #dcdce6;
	border-radius: 8px;
	padding: 0 24px;
}

.button {
	width: 5%;
	height: 30px;
	background: #2094D3;
	border: 0;
	/* padding: 5px; */
	border-radius: 3px;
	color: #fff;
	font-weight: 700;
	margin: 0 10px;
	margin-top: 10px;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	font-size: 14px;
	transition: ease 0.2s;
}

.button:hover {
	filter: brightness(90%);
}

.button:focus {
	background-color: #21409A;
}